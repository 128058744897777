import React from 'react'
import {Link} from "react-router-dom";
import logo from "../images/sklogo.jpg";

function Navbar() {
  return (
    <div>
<div className='fixed-top'>
<nav className="navbar navbar-expand-lg navbar-white bg-white shadow">
  <div className="container-fluid">
   <Link to="/">
    <img src={logo} className='me-0' height="37px"  width="120px" alt='loog'/>
   </Link>
   <a className='appdownload' href='https://play.google.com/store/apps/details?id=com.serviceskarts' target='_blank' rel="noopener noreferrer">Download App <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google-play" viewBox="0 0 16 16">
  <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055zM1 13.396V2.603L6.846 8zM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27"/>
</svg>   <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
  <span class="visually-hidden">Loading...</span></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="nav-link active me-2" aria-current="page" href="#">Home</Link>
        </li>
        <li className="nav-item">
          <a className="nav-link me-2" href="#">About</a>
        </li>
   
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle me-2" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
           Services
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link to="/Homedevelopment" className="dropdown-item" href="#">Home Development</Link></li>
         
            <li><Link to="/Electronics" className="dropdown-item" href="#">Electronics</Link></li>
            <li><Link to="/health" className="dropdown-item" href="#">Healthcare</Link></li>
            <li><Link to="/Travells" className="dropdown-item" href="#">Travells</Link></li>
            <li><Link to="/education" className="dropdown-item" href="#">Education</Link></li>
         
            <li><hr className="dropdown-divider"/></li>
            <li><Link to="/delivery-agent" className="dropdown-item" href="#">Delivery Service</Link></li>
          </ul>
        </li>
        <li className="nav-item">
          <Link to="/Contact" className="nav-link " href="#">Contact</Link>
        </li>
      </ul>
    

        <a href='tel: 9735171820' className="btn btn-success btn-sm" type="submit">HELP LINE</a>
      
    </div>
  </div>
</nav>
</div>
    </div>
  )
}

export default Navbar