import React from 'react'
import Navbar from "../inc/Navbar";
import Hdevelop from './Hdevelop';
import Labour from "../images/labour.jpg";
import ReactWhatsapp from "react-whatsapp";
import { Link } from "react-router-dom";
import AddLabour from "../AddLabour";

function Homed() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <div className="album py-0">

                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                            <li class="breadcrumb-item"><Link to="/Contact" href="#">Contact</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Home Development</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        <div className="col-md-9">

                            <div className="card text-dark bg-white mb-3" >
                                {/* <div className="card-header">Header</div> */}
                                <div className="card-body">

                                    <Hdevelop />

                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className='sticky-top'>
                                <div className="card text-dark bg-light mb-3" >
                                    <div className="card-header"><center>LABOUR BOOKING</center></div>
                                    <img src={Labour} alt="labour" />
                                    <div className="card-body">
                                        <h5 className="card-title">Labour Booking</h5>
                                        <p className="card-text mb-0">Any Type Of Labour Availble, Please Contact Us</p>

                                        <AddLabour/>
                                        <a href='tel: 9735171820' className="callbook mb-1 me-2">Call Book</a>
                                        <ReactWhatsapp number="+91 9735171820" className="wahtsappbook mb-1 me-2" message="I am Interested"> WhatsApp</ReactWhatsapp>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Homed