import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'
import { Link } from "react-router-dom";

import ReactWhatsapp from "react-whatsapp";

import ScrollToTop from "react-scroll-to-top";


import {
    FacebookShareButton, WhatsappShareButton, WhatsappIcon,
    FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterIcon,
} from "react-share";
import Navbar from '../inc/Navbar';



function Electronics() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(9);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [electronics, setElectronics] = useState([])
    const navigate = useNavigate()

    const electronicsCollectionRef = collection(db, "Electronics");
    useEffect(() => {

        const getElectronics = async () => {
            const data = await getDocs(electronicsCollectionRef);
            setElectronics(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getElectronics()
    }, [])
    return (

        <>
<Navbar/>
<br/>
<br/>
       
<br/>
<br/>
         
            <ScrollToTop smooth top="100" color="maroon"/>
          
            <div className="album py-0">

<div className="container">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
            <li class="breadcrumb-item"><Link to="/Contact" href="#">Contact</Link></li>
            <li class="breadcrumb-item active" aria-current="page">Electric</li>
        </ol>
    </nav>
</div>
</div>
         
            <div className="album py-1">

                <div className="container">

                    <div className="row">

                        <div className="col-md-6">

                            <div className="card-body">
                                <input type="text" className="form-control" placeholder="Search" name="location"
                                    onChange={(e) => {
                                        setsearchTerm(e.target.value);
                                    }}
                                />
                                <div className="d-flex justify-content-between align-items-center">




                                </div>


                            </div>


                        </div>

                    </div>

                </div>
            </div>
            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        {electronics.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase()) 
                              



                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((servicekarts) => {
                            return (
                                <div className="col-md-4 mt-3" >
                                    <div className="card mb-4 ">
                                        <img className="card-img-top" src={servicekarts.img} alt="servicekarts" />
                                        <div className="card-body">

                                            <h6 className="card-text"> <b>{servicekarts.title}</b></h6>
                                            <p className="card-text mb-3">{servicekarts.des}</p>
                                            <div className="d-flex justify-content-between align-items-center">

                                                <a href='tel: 9735171820' className="callbook mb-1">Call Book</a>
                                                <ReactWhatsapp number="+91 9735171820" className="wahtsappbook mb-1" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp>

                                            </div>
                                            <br/>
                                            <button type="button" className="share me-1">
                                                Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                                                    <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                                </svg>
                                            </button>  < FacebookShareButton url="https://www.servicekarts.co.in/Electronics" className="me-2" quote={"M B Dhar and Son Jewellers"} hastag={"#WebApp"}
                                            >
                                                <FacebookIcon size={33} round={true} />
                                            </FacebookShareButton>

                                            <  WhatsappShareButton url="https://www.servicekarts.co.in/Electronics" className="me-2" quote={"M B Dhar and Son Jewellers"} hastag={"#WebApp"}
                                            >
                                                <WhatsappIcon size={33} round={true} />
                                            </ WhatsappShareButton>

                                            <  TwitterShareButton url="https://www.servicekarts.co.in/Electronics" className="me-2" quote={"M B Dhar and Son Jewellers"} hastag={"#WebApp"}
                                            >
                                                <TwitterIcon size={33} round={true} />
                                            </ TwitterShareButton>

                                            <  LinkedinShareButton url="https://www.servicekarts.co.in/Electronics" className="me-2" quote={"M B Dhar and Son Jewellers"} hastag={"#WebApp"}
                                            >
                                                <  LinkedinIcon size={33} round={true} />
                                            </ LinkedinShareButton>

                                            <  EmailShareButton url="https://www.servicekarts.co.in/Electronics" quote={"M B Dhar and Son Jewellers"} hastag={"#WebApp"}
                                            >
                                                <  EmailIcon size={33} round={true} />
                                            </ EmailShareButton>

                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>

             


                </div>
            </div>

        </>

    );
}

export default Electronics;



