import React from 'react';
import Navbar from '../inc/Navbar';
import ScrollToTop from "react-scroll-to-top"

import { Link } from "react-router-dom";




function Contact() {
  return (

    <>
      <Navbar />
      <br />
      <br />


      <ScrollToTop smooth top="100" color="Gray" />
      <div className="servicekartskarimpur">


        <br />


        <div className="album py-1">

          <div className="container">

            <div className="row">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li style={{ color: "darkBlue" }} className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="/" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                    <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                  </svg> Back</Link></li>
                  <li style={{ color: "darkBlue" }} className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="/Homedevelopment" href="#">Home Development</Link></li>
                  <li style={{ color: "darkBlue" }} className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="#" href="#">Healthcare</Link></li>
                  <li style={{ color: "darkBlue" }} className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="/Electronics" href="#">Electronics</Link></li>



                  <li className="breadcrumb-item active" aria-current="page">Contact Us</li>

                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 mb-2 bg-light text-dark">
        <div className="album py-0">

          <div className="container">

            <div className="row justify-content-center">

              <div className="col-md-5 mt-3" >


                <h6 className="card-text">SERVICE KARTS</h6>
                <p className="card-text mb-2">Office Address</p>
                <p style={{ color: "maroon" }} className="card-text mb-1">Mahishbathan, Karimpur</p>
                <p style={{ color: "maroon" }} className="card-text mb-2">Pin-741165, Dist- Nadia</p>
                <p style={{ color: "maroon" }} className="card-text">West Bengal | India</p>

                <br />
                <p className="card-text"> <br />Copyright © SERVICE KARTS</p>

                <a className='appdownload' href='https://play.google.com/store/apps/details?id=com.serviceskarts' target='_blank' rel="noopener noreferrer">Download App <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google-play" viewBox="0 0 16 16">
  <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055zM1 13.396V2.603L6.846 8zM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27"/>
</svg></a>

                <div className="d-flex justify-content-between align-items-center">


                </div>

              </div>
              <div className="col-md-4 mt-3" >

                <h6 style={{ color: "Gray" }} className="card-text mb-3">Contact Details</h6>
                <a style={{ color: "maroon" }} href="tel: +91 9735171820" className="mb-4">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                </svg> +91 9735171820</a>
                <br />



                <div className="d-flex justify-content-between align-items-center">

                </div>

              </div>
              <div className="col-md-3 mt-3" >
                <h6 style={{ color: "Gray" }} className="card-text " > <b>Email Contact</b></h6>


                <a href='mailto: serviceskart24@gmail.com'>serviceskart24@gmail.com</a>


                <div className="d-flex justify-content-between align-items-center">

                </div>

              </div>
            </div>
          </div>
        </div>






      </div>



    </>

  );
}

export default Contact;