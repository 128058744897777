import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';



import Home from './components/pages/Home';
import Footer from './components/inc/Footer';
import Contact from './components/pages/Contact';
import Homed from './components/pages/Homed';
import Electronics from './components/pages/Electronics';
import Health from './components/pages/Health';
import Travells from './components/pages/Travells';
import Education from './components/pages/Education';
import Delivery from './components/pages/Delivery';

function App() {
  return (
    <Router>
    
    <div>

     
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/Contact" element={<Contact/>}/>
      <Route axact path="/Homedevelopment" element={<Homed/>}/>
      <Route axact path="/Electronics" element={<Electronics/>}/>
      <Route axact path="/health" element={<Health/>}/>
      <Route axact path="/Travells" element={<Travells/>}/>
      <Route axact path="/education" element={<Education/>}/>
      <Route axact path="/delivery-agent" element={<Delivery/>}/>
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
