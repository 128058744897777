
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyCu6PKQ43WoSkEJHJyTGOHFgHCVgVf9R58",
    authDomain: "service-karts.firebaseapp.com",
    projectId: "service-karts",
    storageBucket: "service-karts.appspot.com",
    messagingSenderId: "449695457187",
    appId: "1:449695457187:web:603ee5157d2a389dae0416",
    measurementId: "G-6T25Z5X11E"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;