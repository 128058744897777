import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import "./Home.css";
import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-router-dom";
import Houseplankarimprur from "../images/home.jpg";
import Health from "../images/health.jpg";
import Electric from "../images/electric.jpg";
import { Typewriter } from 'react-simple-typewriter'
import Travels from "../images/travels-tour.jpg";
import Education from "../images/education.jpg";
import Delivery from"../images/delivery.jpg";

function Home() {
  return (
    <div>
      <Navbar />

      <br />
      <br />
      <Slider />
      <ScrollToTop smooth top="100" color="dark" />

      <div className='servicekarts'>
        <div className="container">

          <div className="row">

            <div className="col-md-11 mt-2">
              <div className="d-flex justify-content-between align-items-center">
                <marquee> <h5 style={{ color: "Darkblue" }} >Welcome to Service Karts, Home Development, Electronics, Healthcare, Travells, Education, Delivery Service Availble</h5></marquee>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-1 mb-0 bg-warning text-seconday">

        <div className="album py-0">

          <div className="container">

            <div className="row">
              <div className="col-md-7">

                <div className="card-body">

                  <h3 className="card-text mb-2" style={{ paddingTop: "1rem", margin: "auto 0", color: "Gray" }}> <b> OUR SERVICES <span style={{ color: "Darkblue", fontWeight: "bold", marginleft: '7px' }}>
                    <Typewriter
                      loop
                      cursor
                      cursorStyle="_"
                      typeSpeed={90}
                      deleteSpeed={50}
                      delaySpeed={1000}
                      words={[" HOME DEVELOPMENT ", " HEALTHCARE", "ELECTROICS", "TRAVELLS", "EDUCATION", "DELIVERY AGENT"]}
                    />
                  </span>
                  </b></h3>

                  <p className="card-text mb-3">Service Karts Trusted Service Company </p>

                </div>


              </div>
            </div>
          </div>
        </div>

      </div>
      <div className='album py-2'>
        <div className="container">

          <div className="row">

            <div className="col-md-4 mt-2">
              <div className="card" >
                <Link to="/Homedevelopment">
                <img src={Houseplankarimprur} className="card-img-top" alt="Houseplankarimprur"/>
                </Link>
                <div className="card-body">
                  <h5 className="card-title">Home Development</h5>
                  <p className="card-text">100+ Home Development Project Experience </p>
                  <br />
                  <Link to="/Homedevelopment" href="#" className="learnbutton">Learn More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                  </svg></Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card" >
                <Link to="/Electronics">
                <img src={Electric} className="card-img-top" alt="Electronics"/>
                </Link>
                <div className="card-body">
                  
                  <h5 className="card-title">Electronics</h5>
                  <p className="card-text">Best Electronics Services in West Bengal</p>
                  <br />
                  <Link to="/Electronics" href="#" className="learnbutton">Learn More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                  </svg></Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card" >
                <Link to="/health">
                <img src={Health} className="card-img-top" alt="Electric" />
                </Link>
                <div className="card-body">
                  <h5 className="card-title">Healthcare</h5>
                  <p className="card-text">Healthcare Related iassue 10mins Solve , Online Doctor Consult Appointment Facility</p>
                  <Link to="/health" href="#" className="learnbutton">Learn More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                  </svg></Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card" >
                <Link to="/Travells">
                <img src={Travels} className="card-img-top" alt="Travels"/>
                </Link>
                <div className="card-body">
                  <h5 className="card-title">Travells</h5>
                  <p className="card-text">Car, Bus Booking Availble</p>
                  <Link to="/Travells" href="#" className="learnbutton">Learn More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                  </svg></Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card" >
                <Link to="/education">
                <img src={Education} className="card-img-top" alt="Education"/>
                </Link>
                <div className="card-body">
                  <h5 className="card-title">Education</h5>
                  <p className="card-text">D. El. Ed Admission Open</p>
                  <Link to="/education" href="#" className="learnbutton">Learn More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                  </svg></Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card" >
                <Link to="/delivery-agent">
                <img src={Delivery} className="card-img-top" alt="Delivery"/>
                </Link>
                <div className="card-body">
                  <h5 className="card-title">Delivery Agent</h5>
                  <p className="card-text">Delivery Facility Availble</p>
                  <Link to="/delivery-agent" href="#" className="learnbutton">Learn More <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                  </svg></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<br/>

<div className='backcolore'>
<a className='appdownload' href='https://play.google.com/store/apps/details?id=com.serviceskarts' target='_blank' rel="noopener noreferrer">Download App <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google-play" viewBox="0 0 16 16">
  <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055zM1 13.396V2.603L6.846 8zM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27"/>
</svg>   <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
  <span class="visually-hidden">Loading...</span></a>
</div>
    </div >
  )
}

export default Home